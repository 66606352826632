.dashboard-container{
    width: 100%;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0 0 5px 1px lightgray;
    border-radius: 10px;
    max-height: 80vh;
}

.dashboard-header{
    max-height: 20%;
    text-align: center;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dashboard-body{
    height: 80%;
    width: 100%;
    overflow: auto;
    padding-bottom: 20px;
}

.dashboard-body-title{
    text-align: center;
    padding-top: 1rem;
    position: sticky;
    top: 0;
    z-index: 10;
}

.header-text{
    font-size: 30px;
}

.dashboard-body-content{
    padding: 0 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}