.login-inputs-container{
    height: fit-content;
    padding: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 5px 1px gray;
    background-color: #0060FF;
    width: 80%;
    max-width: 450px;
}

.login-container-logo{
    background-image: url("https://miro.medium.com/max/3150/1*cyOG0qXiWWvFRj8cIfSktQ.png");
    background-size: cover;
    background-repeat: no-repeat;
    height: 150px;
    width: 150px;
    border-radius: 50%;
    box-shadow: 0 0 5px 1px white;
}

.container-error{
    text-shadow: 0 0 1px black;
}
