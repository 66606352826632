.bank-image{
    max-width: 70px;
    max-height: 70px;
    border: 1px solid gray;
}

.bank-item{
    color: black;
    overflow: hidden;
    word-break: break-all;
}

.bank-item h4{
    font-size: 15px;
}

.bank-item:hover{
    background-color: lightgray;
    border-left: 0.25rem solid gray;
}

@media screen and (max-width: 600px) {
    .bank-item{
        max-width: 50px;
        max-height: 50px;
    }
    .bank-item h4{
        font-size: 1vw;
    }
}