.country-image{
    max-width: 70px;
    max-height: 70px;
    border: 1px solid gray;
}

.country-item{
    color: black;
    overflow: hidden;
    text-wrap: normal;
}

.country-item:hover{
    background-color: lightgray;
    border-left: 0.25rem solid gray;
}

@media screen and (max-width: 600px) {
    .country-image{
        max-width: 50px;
        max-height: 50px;
    }
    .country-item h4{
        font-size: 3vw;
    }
}